import React from 'react'
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../components/SEO'
import { ReportsContainer } from '../../containers/ReportsContainer'
import LayoutMain from '../../layout'

const Reports: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Reportes" />
      <WithPrivateRoute component={ReportsContainer} />
    </LayoutMain>
  )
}

export default Reports
