// Packages
import React from 'react'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'

// Hooks
import { useConfigFlag } from '../../hooks/useConfigFlag'

// Redux
import RoutesTypes from '../../redux/ducks/routes/types'

// Container
import { callProfileRedux } from '../ProfileContainer'

// Styled Components
import { StyledReportsContainer } from './styledComponents'

// Components
import ButtonMenuReport from '../../components/ButtonMenuReports'

// Constants
import { ICON_MENU } from '../../data/constants/reports/salesReport'
import { PermissionsTypes } from '../../types/PermissionsTypes'

interface RoutesState {
  routes: RoutesTypes.RoutesInitialState
}

const getActionsByModule = (
  permissions: PermissionsTypes.GetPermission,
  routes: RoutesTypes.Menu | undefined
) => {
  if (permissions === undefined || routes === undefined) return []
  const actionsUniq: Array<PermissionsTypes.Action> = []
  const actions: Array<PermissionsTypes.Action> = []
  permissions.modules.forEach((module: PermissionsTypes.Module) => {
    module.actions.forEach((action: PermissionsTypes.Action) => {
      actions.push({
        appModulePemID: module.appModulePemID,
        actionName: module.moduleName,
        actionID: action.actionID,
        actionPath: routes?.subpath.filter(
          (x) => x.name === module.moduleName
        )[0].path
      })
    })
  })
  actions.forEach((element) => {
    if (
      actionsUniq.find((x) => x.actionName === element.actionName) === undefined
    ) {
      actionsUniq.push(element)
    }
  })
  return actionsUniq
}

export const ReportsContainer: React.FC = () => {
  const { routesList } = useSelector((state: RoutesState) => state.routes)
  const { user_data } = useSelector(callProfileRedux)
  const { value: flagRewards } = useConfigFlag('APP_PEM_REPORTS_REWARDS')
  const { value: flagBeesPay } = useConfigFlag('APP_PEM_REPORTS_BEES_PAY')
  const { value: flagInventory } = useConfigFlag('APP_PEM_REPORTS_INVENTORY')
  const { value: flagHistoryOrders } = useConfigFlag('APP_PEM_REPORTS_BEERU')
  const { value: flagShopKeepers } = useConfigFlag('shopkeepers')
  const reportsList = routesList.find((x) => x.path === '/reports')
  const permissions = user_data.getProfile?.permissions.filter(
    (x: PermissionsTypes.GetPermission) => x.groupName === 'Reportes'
  )[0]
  const storeId = user_data.getProfile?.stores[0].storeID

  const renderModules = getActionsByModule(permissions, reportsList)

  renderModules.forEach((module: any) => {
    module['url'] = ICON_MENU[module.appModulePemID as keyof typeof ICON_MENU]
  })

  const searchShopKeeper = (flagShopKeepers: string[] = []) => {
    if (flagShopKeepers.includes(storeId)) {
      return true
    }
    return !flagRewards
  }

  const getValueFeatureFlag = (appModulePemID: string) => {
    switch (appModulePemID) {
      case 'APP_PEM_REPORTS_REWARDS':
        return searchShopKeeper(flagShopKeepers as string[])
      case 'APP_PEM_REPORTS_BEES_PAY':
        return !flagBeesPay
      case 'APP_PEM_REPORTS_INVENTORY':
        return !flagInventory
      case 'APP_PEM_REPORTS_BEERU':
        return !flagHistoryOrders
      default:
        return false
    }
  }

  return (
    <StyledReportsContainer>
      {renderModules.map((item, index) => {
        return (
          <ButtonMenuReport
            key={index}
            img={item.url}
            title={item.actionName}
            isEmpty={false}
            isHidden={getValueFeatureFlag(item.appModulePemID)}
            onClick={() =>
              navigate(
                `${item.actionPath}?storeId=${storeId}${
                  item.actionID.includes('_02')
                    ? `&userId=${user_data.getProfile?.userID}`
                    : ''
                }`
              )
            }
          />
        )
      })}
      <ButtonMenuReport img="" title="" isEmpty={true} isHidden={false} />
    </StyledReportsContainer>
  )
}
