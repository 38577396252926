// Packages

import React from 'react'

// Styled Components

import {
  StyledButtonMenuReport,
  StyledIconButtonMenuReport,
  StyledTitleButtonMenuReport
} from './styledCompnents'

type props = {
  img: string | undefined
  title: string
  isHidden: boolean
  isEmpty: boolean
  onClick?: () => void
}

const ButtonMenuReports: React.FC<props> = (props) => {
  const { title, img, isHidden, isEmpty, onClick } = props
  return (
    <StyledButtonMenuReport
      isEmpty={isEmpty}
      isHidden={isHidden}
      onClick={onClick}
    >
      <StyledIconButtonMenuReport src={img} />
      <StyledTitleButtonMenuReport>{title}</StyledTitleButtonMenuReport>
    </StyledButtonMenuReport>
  )
}

export default ButtonMenuReports
