// Assets
import iconMenuReportSales from '../../../../data/assets/icons/iconPOS.webp'
import iconMenuReportInventory from '../../../../data/assets/icons/iconInventary.webp'
import iconMenuReportRewards from '../../../../data/assets/icons/iconBonuses.webp'
import iconMenuReportBees from '../../../../data/assets/icons/iconBees.webp'
import iconMenuReportBeerU from '../../../assets/icons/historyOrdersIcon.webp'

export const ICON_MENU = {
  APP_PEM_REPORTS_BEES_PAY: iconMenuReportBees,
  APP_PEM_REPORTS_REWARDS: iconMenuReportRewards,
  APP_PEM_REPORTS_SALES: iconMenuReportSales,
  APP_PEM_REPORTS_INVENTORY: iconMenuReportInventory,
  APP_PEM_REPORTS_BEERU: iconMenuReportBeerU
}
