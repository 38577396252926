// Packages

import styled from 'styled-components'

export const StyledReportsContainer = styled.div`
  padding: 5%;
  margin: 52px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
