// Packages

import styled from 'styled-components'

type StyledButtonMenuReportTypes = {
  isHidden: boolean
  isEmpty: boolean
}

export const StyledButtonMenuReport = styled.div<StyledButtonMenuReportTypes>`
  width: 144px;
  height: 170px;
  border-radius: 8px;
  box-shadow: 1px 1px 13px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 12px 24px;
  opacity: ${({ isEmpty }) => (isEmpty ? 0 : 1)};
  cursor: pointer;
  @media (min-width: 694px) {
    display: ${({ isEmpty }) => isEmpty && 'none'};
  }
  @media (max-width: 373px) {
    width: 200px;
    height: 210px;
  }
`

export const StyledIconButtonMenuReport = styled.img`
  width: 57px;
  height: 57px;
`

export const StyledTitleButtonMenuReport = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  margin: 20px 15px 0;
  text-align: center;
`
